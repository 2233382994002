@import '../../../App';

.search_bar {
    height: 100%;
    flex: 12.5;
    display: flex;
    align-items: center;
    position: relative;
    transition: all 0.3s ease-in-out;

    @media (max-width: $small-trigger) {
        flex: none;
        justify-content: flex-end;
        width: 75px;
        border-left: 1px solid #e6e6e6;

        &.clicked {
            width: 100%;

            .search_icon {
                left: 15px;
            }
        }
    }

}

.search_bar_contents {
    background-color: white;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;

    @media (max-width: $small-trigger) {
        z-index: 2;
    }
}

.search_input {
    height: 38px;
    width: 100%;
    border: 1px solid #e6e6e6;
    border-radius: 4px;
    font-size: 14px;
    outline: none;
    padding-left: 45px;
    z-index: 2;
    transition: all 0.2s ease-in-out;

    @media (max-width: $small-trigger) {
        opacity: 0;
        outline: none;
        border: none;
        border-radius: 0;
        &.clicked {
            opacity: 1;
        }
    }

}

.search_input:focus {
    border: 1px solid $water-blue;

    @media (max-width: $small-trigger) {
        box-shadow: none;
        border: none;
    }
}

.search_icon {
    position: absolute;
    left: 15px;
    top: 20px;
    z-index: 3;
    transition: all 0.3s ease-in-out;

    svg {
      fill: $gray4;
    }

    @media (max-width: $small-trigger) {
        left: 28px;
    }
}

.search_results {
    box-sizing: border-box;
    width: 100%;
    position: absolute;
    top: 34px;
    z-index: 1;
    border: 1px solid #e6e6e6;
    background-color: white;
    border-radius: 4px;

    @media (max-width: $small-trigger) {
        top: 44px;
        left: -1px;
        width: calc(100% + 2px);
    }
}

.search_result_row {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 45px;

    @media (max-width: $small-trigger) {
        &:first-child {
            border-top: 1px solid #ebedef;
        }
    }
    
    &:nth-child(1) {
        margin-top: 20px;
    }

    &:last-child {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
    }

    &:hover {
        background-color: #eeeeee;
        cursor: pointer;
    }
}

.active {
    background-color: #f3f3f3;
    cursor: pointer;

    &:hover {
        background-color: #e9e9e9;
    }
}

.search_result_image_holder {
    height: 30px;
    width: 30px;
    margin-left: 15px;
    margin-right: 16px;
    box-sizing: border-box;

    svg {
        min-width: 30px;
        min-height: 30px;
    }
}

.search_result_text {
    font-size: 15px;
    margin-right: 8px;
}
.search_result_email {
    font-size: 13px;
    color: gray;
}

.search_results_show_all_wrapper {
    padding-left: 15px;
    padding-top: 6px;
    padding-bottom: 11px;

    &:hover {
        background-color: #eeeeee;
        cursor: pointer;
    }
}

.search_results_show_all_wrapper_alone {
    @extend .search_results_show_all_wrapper;
    margin-top: 20px;
    padding-top: 10px;

    @media (max-width: $small-trigger) {
        border-top: 1px solid #ebedef;
    }
}

.search_results_show_all {
    font-size: 12px;
    font-weight: 500;
    color: $water-blue;
    
}
