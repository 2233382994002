@import '../../App';

.modal_contents {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    align-self: center;
    text-align: center;
    padding: 35px 16px 45px;
    width: 550px;
    height: auto;
    border-radius: 4px;
    background-color: white;
    animation: show 0.35s ease forwards;

    @media (max-width: $small-trigger) {
        width: 85vw;
        padding-left: 20px;
        padding-right: 20px;
        height: 220px;
        div {
            display: flex;
        }
    }

    .title {
        width: 75%;
        font-size: 18px;
        color: $gray1;
        margin: 4px auto;
    }

    .description {
        width: 75%;
        font-size: 14px;
        color: $gray3;
    }

    .btn_actions_container {
        display: flex;
        width: 75%;
        justify-content: center;
        margin-top: 25px;
    }

    .btn_actions_container > * {
        margin: auto 8px;
    }

};

@keyframes show {
    from {
        transform: scale(0.5);
        opacity: 0;
    }

    to {
        transform: scale(1);
        opacity: 1;
    }
}