@import '../../App';

$transition-time: 0.3s;
$button-color: $water-blue;
$button-hover-color: white;
$secondary-button-color: $gray3;
$red-button-color: $danger-red;
$grey-button-color: $inactive-grey;
$grey-button-background-color: $very-light-grey;

.button {
    margin: 0 10px 0 10px;
    outline: none;
    border: 1px solid $button-color;
    background-color: $button-hover-color;
    border-radius: 20px;
    padding: 10px 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: $button-color;
    min-width: 82px;
    font-size: 14px;
    font-weight: bold;
    transition: color $transition-time, background-color $transition-time;
    text-transform: uppercase;
    .text_holder {
        margin: auto 0;
    }

    svg {
        height: 100%;
        fill: $button-color;
        transition: fill $transition-time;
        g {
            stroke: $button-color;
            stroke-width: 1.5;
            fill: $button-hover-color;
            transition: stroke $transition-time;
        }
    }

    .icon_holder {
        margin-right: 15px;
    }

    .hidden_if_big {
        display: none;
    }
}

.button_hoverable:hover {
    color: $button-hover-color;
    background-color: $button-color;
    cursor: pointer;

    svg {
        fill: $button-hover-color;
        g {
            stroke: $button-hover-color;
            fill: $button-color;
        }
    }
}

/***********************************************************************************************************************
  Grey instead of blue
***********************************************************************************************************************/

.button {
    &.secondary {
        background-color: $button-hover-color;
        color: $secondary-button-color;
        border-color: $secondary-button-color;

        svg {
            fill: $secondary-button-color;
            g {
                stroke: $secondary-button-color;
                fill: $button-hover-color;
            }
        }
    }
}

.button_hoverable:hover {
    &.secondary {
        background-color: $secondary-button-color;
        color: $button-hover-color;

        svg {
            fill: $button-hover-color;
            g {
                stroke: $secondary-button-color;
                fill: $button-hover-color;
            }
        }
    }
}

/***********************************************************************************************************************
  Red instead of blue
***********************************************************************************************************************/
.button {
    &.red {
        background-color: $red-button-color;
        color: $button-hover-color;
        border-color: $red-button-color;

        svg {
            fill: $button-hover-color;
            g {
                stroke: $button-hover-color;
                fill: $red-button-color;
            }
        }
    }
}

.button_hoverable:hover {
    &.red {
        color: $red-button-color;
        background-color: $button-hover-color;

        svg {
            fill: $red-button-color;
            g {
                stroke: $button-hover-color;
                fill: $red-button-color;
            }
        }
    }
}


/***********************************************************************************************************************
  Grey instead
***********************************************************************************************************************/
.button {
    &.grey {
        background-color: $grey-button-background-color;
        color: $grey-button-color;
        border-color: $grey-button-color;

        svg {
            fill: $grey-button-color;
            g {
                stroke: $grey-button-color;
                fill: $grey-button-background-color;
            }
        }
    }
}

.button_hoverable:hover {
    &.grey {
        color: $grey-button-background-color;
        background-color: $grey-button-color;

        svg {
            fill: $grey-button-background-color;
            g {
                stroke: $grey-button-color;
                fill: $grey-button-background-color;
            }
        }
    }
}


/***********************************************************************************************************************
  Disabled
***********************************************************************************************************************/
.button {
    &.disabled {
        background-color: $grey-button-background-color;
        color: $grey-button-color;
        border-color: $grey-button-color;
        cursor: not-allowed;

        svg {
            fill: $grey-button-color;
            g {
                stroke: $grey-button-color;
                fill: $grey-button-background-color;
            }
        }
    }
}

.button_hoverable:hover {
    &.disabled {
        color: $grey-button-background-color;
        background-color: $grey-button-color;

        svg {
            fill: $grey-button-background-color;
            g {
                stroke: $grey-button-color;
                fill: $grey-button-background-color;
            }
        }
    }
}


/***********************************************************************************************************************
  Reversed
***********************************************************************************************************************/

.button {
    &.reversed {
        background-color: $button-color;
        color: $button-hover-color;

        svg {
            fill: $button-hover-color;
            g {
                stroke: $button-hover-color;
                fill: $button-color;
            }
        }
    }
}

.button_hoverable:hover {
    &.reversed {
        background-color: $button-hover-color;
        color: $button-color;

        svg {
            fill: $button-color;
            g {
                stroke: $button-hover-color;
                fill: $button-color;
            }
        }
    }
}

.button_small {
    padding: 0;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 0px;

    .text_holder {
        text-transform: uppercase;
        display: none;
    }

    .icon_holder {
        margin-right: 0;
    }

    .hidden_if_big {
        display: flex;
    }
}

/***********************************************************************************************************************
  Responsiveness
***********************************************************************************************************************/
@media (max-width: $small-trigger) {
    .button_responsive {
        padding: 0;
        width: 36px;
        height: 36px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 0px;

        .text_holder {
            display: none;
        }

        .icon_holder {
            margin-right: 0;
        }

        .hidden_if_big {
            display: flex;
        }
    }
}
