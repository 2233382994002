/* Colors */
$very-light-grey: #F3F3F3;
$turquoise: #00b6a5;
$turquoise-darker: #009D8C;
$turquoise-lighter: #1AD0BF;
$water-blue: #1083c6;
$water-blue-darker: #006AAD;
$water-blue-lighter: #2A9DE0;
$water-blue-lightest: #E3F1FA;
$almost-white: rgba(235, 237, 239, 0.2);
$danger-red: #E53935;
$sexy-green: #00C853;

$accepted-green: #1be68c;
$pending-orange: #f5a623;
$denied-red: #fd5555;
$inactive-grey: #adb1b5;
$dusty-grey: #a9a3a2;
$gray1: #333333;
$gray2: #4F4F4F;
$gray3: #828282;
$gray4: #C4C4C4;
$gray5: #E0E0E0;
$gray6: #F2F2F2;

$office-orange:#DC3E15;

$mostly-black: #e6b9e6;

/* Size */
$large-trigger: 1100px;
$medium-trigger: 900px;
$small-trigger: 700px;
$smaller-trigger: 500px;
$very-small-trigger: 430px;
$very-very-small-trigger: 365px;
$medium-trigger-vertical: 750px;
$small-trigger-vertical: 640px;
$smaller-trigger-vertical: 615px;

$nav-bar-height: 65px;
$side-padding: 20px;
$banner-height: 85px;

/* Z-indexes */
$nav-bar-z-index: 1000;
$mobile-menu-z-index: 1200;
$drawer-z-index: 800;
$modal-z-index: 2000;


/***********************************************************************************************************************
   Typo Section
***********************************************************************************************************************/
.name_header {
  margin: 0 0 4px 0;
  font-size: 15px;
  font-weight: 500;
  color: $water-blue;
  cursor: pointer;
}

.normal_header {
  font-size: 12px;
  margin: 0 0 4px 0;
  color: #adb1b5;
}

.normal_text {
  font-size: 14px;
  color: #000000;
  margin: 0;
}

.error_text {
  font-size: 12px;
  color: $danger-red;
  margin: 0 8px;
  font-weight: 500;
}

.normal_subheader {
    font-size: 15px;
    color: #000000;
    margin: 0;
}

.bold_subheader {
    font-size: 15px;
    font-weight: 500;
    color: #000000;
    margin: 0;
}

.box_header {
  font-size: 24px;
  color: black;
  font-weight: 500;
  margin: 0;
}

.box_header2 {
  margin-left: 20px !important;
  font-size: 16px;
  color: $gray3;
  margin: 0;
}

.blue_text {
  color: $water-blue;
}

.link_text {
  color: $water-blue;
  text-decoration: underline;
}

.link_text:hover {
  cursor: pointer;
}

.link_grey_small {
  font-size: 12px;
  text-decoration: underline;
  color: $dusty-grey;

  &:hover {
    cursor: pointer;
  }

}

.capitalized {
    &::first-letter {
        text-transform: capitalize;
    }
}

/***********************************************************************************************************************
   Other
***********************************************************************************************************************/
.white-container {
  background-color: white;
  border-radius: 4px;
}

.app-content {
  width: 100vw;
  height: calc(100vh - #{$nav-bar-height});
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  z-index: 1;
  position: relative;
}

.app_content_gray_background {
  @extend .app-content;
  background-color: $gray6;
}

.custom_submit_button {
  border: none;
  width: 100%;
  height: 55px;
  background-color: $water-blue;
  color: white;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;

  &:hover:enabled {
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }

  &[disabled] {
    opacity: 0.3;
  }

}


body {
  position: relative;
  background-color: $gray6 !important;
}

.generic_input {
  border: 1px solid $very-light-grey;
  height: 60px;
  width: 100%;
  box-sizing: border-box;
  font-size: 14px;
  color: black;
  padding-left: 20px;

  &:focus {
    outline: none;
  }

}
.MuiPickersCalendarHeader-label,.MuiPickersMonth-monthButton{
  text-transform: uppercase;
}

