
.loaded_picture {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.fallback_svg {
  width: 100%;
  height: 100%;
  min-width: 35px;
  min-height: 35px;
}