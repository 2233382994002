@import "../../../App";

.hamburger {

  width: 20px;
  height: 16px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
}

.line_one, .line_two, .line_three {

  width: 100%;
  height: 2px;
  background-color: $water-blue;

}