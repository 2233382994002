@import '../../App';


/***********************************************************************************************************************
   Desktop
***********************************************************************************************************************/
.desktop_content {

  height: $nav-bar-height;
  box-sizing: border-box;
  border-bottom: solid 1px #ebedef;

  position: relative;
  z-index: $nav-bar-z-index;
  background-color: white;
  display: flex;
  align-items: center;

}

.logo {
  width: 45px;
  height: auto;
  margin: 0 42px;
}

.search_bar {
  height: 38px;
  flex: 1;
  border: 1px solid #e6e6e6;
}

/***********************************************************************************************************************
   Mobile
***********************************************************************************************************************/

.mobile_content {
  display: none;
  height: $nav-bar-height;
  border-bottom: solid 1px #ebedef;
  background-color: white;
}

.mobile_right_content {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: space-between;
}

.mobile_rightmost_content {
    display: flex;
    
}

.mobile_logo_holder {
  position: absolute;
  z-index: 1;
}

.__holder {
    // flex: 1;
  height: 100%;
  width: calc(100vw * 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
}

.mobile_search_holder {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    margin-right: 20px;

    @media (max-width: $small-trigger) {
        margin-right: 0;
    }
    
    // width: 20px;
//   composes: __holder;
    // width: 100%;
//   left: calc(100vw * 0.4);
}

.mobile_bell_holder {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 74px;
    border-left: 1px solid $very-light-grey;
}

.mobile_hamburger_holder {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 74px;
    border-left: 1px solid $very-light-grey;
}


.mobile_search_input {

//   height: 30px;
//   border: 1px solid $very-light-grey;
//   font-size: 16px;
//   box-sizing: border-box;

//   &:focus {
//     outline: none;
//   }

}

.mobile_input_holder {
//   position: absolute;
//   left: calc(100vw * 0.2);
//   width: calc(100vw * 0.6);
//   height: 100%;
//   display: flex;
//   padding: 10px;
//   justify-content: center;
//   align-items: center;
//   box-sizing: border-box;
}

/***********************************************************************************************************************
   Responsiveness
***********************************************************************************************************************/

@media (max-width: $small-trigger) {

  .mobile_content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .desktop_content {
    display: none;
  }

}