@import '../../App';

.modal_backdrop {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: $modal-z-index;
    background-color: rgba(0, 0, 0, 0.5);
}

.modal_page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: calc(100vw - 40px);
    margin-top: $nav-bar-height;
}

.modal_page_fullscreen_mobile {
    @extend .modal_page;
}


@media (max-width: $small-trigger) {

    .modal_page_fullscreen_mobile {
        max-width: 100vw;
        height: 100vh;
        margin-top: 0;
    }
}

@media (max-height: $small-trigger-vertical) {

    .modal_page_fullscreen_mobile {
        top: 0;
    }
  
}