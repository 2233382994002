@import '../../../App';

.profile_dropdown_container {
    position: absolute;
    // position: fixed;
    // top: 65px;
    right: 0;
    background-color: white;
    display: flex;
    flex-direction: column;
    z-index: 3;
    border-left: solid 1px #e6e6e6;
    border-right: solid 1px #e6e6e6;

    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;

    div:last-child {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
    }

    .profile_dropdown_row {
        height: 49px;
        width: 163px;
        display: flex;
        align-items: center;
        padding-left: 20px;
        border-bottom: solid 1px #e6e6e6;

        &:hover:not(.disabled) {
            background-color: #c5cbe1;
            cursor: pointer;
            .arrow_up {

                div {
                    border-bottom: 12px solid #c5cbe1;
                }
            }
        }

        &.disabled {

            .profile_dropdown_text {
                opacity: 0.2;
            }
        }
    }
}

.profile_dropdown_text {
    font-size: 12px;
    text-transform: capitalize;
}

.profile_picture_holder {
    margin: 0 15px 0 30px;
    height: 45px;
    width: 45px;
    display: flex;
    align-items: center;
}

.image_wrapper {
    height: 35px;
    width: 35px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.arrow_up {
    position: absolute;
    top: -13px;
    right: 18px;
    width: 0;
    height: 0;
    border-left: 13px solid transparent;
    border-right: 13px solid transparent;
    border-bottom: 13px solid #e6e6e6;
    
    div {
        position: absolute;
        top: 2px;
        right: -12px;
        width: 0;
        height: 0;
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
        border-bottom: 12px solid white;
    }
    
}