@import '../../../App';

.container {
  height: 100%;
  flex: 0.5;
  display: flex;
  justify-content: center;
  a {
    text-decoration: none;
  }
}

.link_holder {
  margin-left: 16px;
  display: flex;
  align-items: center;
}

.nav_link {
  text-decoration: none !important;
  border-radius: 4px;
  padding: 12px;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.35s ease;
}
.nav_link:hover {
  background-color: $water-blue-lightest !important;
}

.selected_link {
  background-color: $water-blue-lightest !important;
  color: $water-blue !important;
}
